














































import Vue from 'vue'

import Calendar, {CalendarEvent} from '@/components/Calendar.vue'
import sohhbetSettings from '@/content/sohhbet-settings.json'
import sohhbetMentors from '@/content/sohhbet-mentors.json'
import luzPeima, {MoeedPeima} from '@/content/luz-peima.json'
import oganeyZman from '@/content/oganey-zman.json'

export default Vue.extend({
  name    : 'SchoolCalendar',
  components: { Calendar },
  computed: {
    events(): CalendarEvent[] {
      return luzPeima.moadim.map(moeed => {
        const settingData = sohhbetSettings[moeed.setting]

        if (!settingData)
          throw Error('Wrong school setting')

        const mergeMoeedWithSetting = (moeed: MoeedPeima): MoeedPeima => {
          const {class: settingClass, duration, isBackground, mentors} = settingData
          
          return {
            ...{class: settingClass, duration, isBackground, mentors},
            ...moeed
          }
        }
        
        const {zman, isBackground, class: className, duration, mentors} = mergeMoeedWithSetting(moeed)
        const mentorsData = (mentors && mentors.length) ? mentors.map(_ => sohhbetMentors[_]) : null

        const {subject, discipline, content} = settingData

        const title = subject
        const subtitle = discipline
        const titleFull = title + `${subtitle ? ': ' + subtitle : ''}`
        const description = content
        const descriptionFooter = mentorsData ? `<footer>מעבירים: ${mentorsData.map(_ => _.name).join(', ')}</footer>` : '' 
        
        const zmanToTime = (zman: [string, number, string]) => {
          const [shavuaa, yom, shaaa] = zman

          const sunday = new Date(`${oganeyZman.shavuot[shavuaa]}T${shaaa}`)
          const day = sunday.addDays(yom - 1)

          return new Date(day)
        }

        const start = zmanToTime(zman)
        const decimalTimeToMilliseconds = (time: number): number => 
          time * 60 * 60 * 1000

        const end = new Date(start.getTime() + decimalTimeToMilliseconds(duration))
        
        return {
          start, end, title, subtitle, titleFull,
          description: description + descriptionFooter, 
          className, 
          isBackground: isBackground || false
        }
      })
    }
  },
  methods: {
    filterTitleByStartDate(date: Date): string {
      const timeToShavuaa = (date: Date): string => {
        const weekFound = Object.entries(oganeyZman.shavuot)
          .find(([, week]) => week === date.format(''))

        return weekFound ? weekFound[0] : 'null'
      }
      return timeToShavuaa(date)
    },

    onMoeedClickEvent(event: any) {
      this.selectedEvent = event
      this.$bvModal.show('calendar-modal')
      
      this.$root.$once('bv::modal::hide', () => {
        this.$bvModal.hide('calendar-modal')
        // this.selectedEvent = {}
      })
    }
  },
  data: () => ({
    selectedEvent: {},
  })
})
