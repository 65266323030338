
































import Vue from 'vue'

import SchoolCalendar  from '@/components/SchoolCalendar.vue'
import DrashTooltip from '@/components/DrashTooltip.vue'

export default Vue.extend({
  components: {SchoolCalendar, DrashTooltip},
  data: function() {
    return {}
  }
})
